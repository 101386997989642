


export const majorDonater = [
    {
        id: 1,
        name: 'Rajib Chattopadhyay',
        image: "p1.jpg"
    },
    {
        id: 2,
        name: 'Pradip Maity',
        image: "p2.jpg"
    },
    {
        id: 3,
        name: 'Arpita Datta',
        image: "p3.jpg"
    },
    {
        id: 4,
        name: 'Biswanath Dhara',
        image: "p4.jpg"
    },
    {
        id: 5,
        name: 'Sourav Das',
        image: 'p5.jpg'
    },
    {
        id: 6,
        name: 'Sukanya Das',
        image: 'p6.jpg'
    },
    {
        id: 7,
        name: 'Shrabani Maity',
        image: 'p7.jpg'
    },
    {
        id: 8,
        name: 'Biswajit Mondal',
        image: 'p8.jpg'
    },
    {
        id: 9,
        name: 'Sayantani Paul',
        image: 'p9.jpg'
    },
    {
        id: 10,
        name: 'Siddhartha Das',
        image: 'p10.jpg'
    },
    {
        id: 11,
        name: 'Saikat Samanta',
        image: 'p11.jpg'
    },
    {
        id: 12,
        name: 'Arijita Santra',
        image: 'p12.jpg'
    },
    {
        id: 13,
        name: 'Sumit Hazra',
        image: 'p13.jpg'
    },
    {
        id: 14,
        name: 'Nilanjan Giri',
        image: 'p14.jpg'
    },
    {
        id: 15,
        name: 'Raja Paul',
        image: 'p15.jpg'
    },
]