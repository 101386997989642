import jayoshri from '../assets/image/volunteers/jayoshri-mallick.jpg'
import sandhini from '../assets/image/volunteers/sandhini-das.jpg'
import sayantani from '../assets/image/volunteers/sayantani-das.jpg'
import urmisha from '../assets/image/volunteers/urmisha-dutta.jpg'
import subhadip from '../assets/image/volunteers/subhadeep-sahu.jpg'

export const volunteers = [
    {
        id: 1,
        name: 'Jayoshri Mallick',
        image: jayoshri
    },
    {
        id: 2,
        name: 'Sandhini Das',
        image: sandhini
    },
    {
        id: 3,
        name: 'Sayantani Das',
        image: sayantani
    },
    {
        id: 4,
        name: 'Urmisha Dutta',
        image: urmisha
    },
    {
        id: 5,
        name: 'Subhadeep Sahu',
        image: subhadip
    }
]