// import member profile photo
import subrata_profile from "../assets/image/members/profile-subrata.jpeg";
import pushpendu_profile from "../assets/image/members/profile-pushpendu.jpeg";
import srimanta_profile from "../assets/image/members/profile-srimanta.jpeg";
import avijit_profile from "../assets/image/members/profile-avijit.jpeg";
import titam_profile from "../assets/image/members/profile-titam.jpeg";
import sumit_profile from "../assets/image/members/profile-sumit.jpeg";
import dipankar_profile from "../assets/image/members/profile-dipankar.jpeg";
import uttam_profile from "../assets/image/members/profile-uttam.jpeg";
import parvez_profile from "../assets/image/members/profile-parvez.jpeg" ;
import sayan_profile from "../assets/image/members/profile-sayan.jpeg" ;
import zahin_profile from "../assets/image/members/profile-zahin.jpeg" ;
import subhadip_profile from "../assets/image/members/profile-subhadip.jpeg" ;
import soubhik_profile from "../assets/image/members/profile-soubhik.jpeg" ;
import sumit2_profile from "../assets/image/members/profile-sumit2.jpeg" ;
// Backend Url
export const base_url = "https://dana-back-end.onrender.com";

// All Member's Details
export const allmembers = [
  {
    name: "Subrata Ranjan Dhara",
    phone: "9734259145",
    mail: "subratardhara@gmail.com",
    address: "Panskura, Purba Medinipur, West Bengal, PIN-721152",
    about: "Ph.D Research Scholar, Organic Chemistry.",
    about1:
      "He completed master of science in Chemistry with Organic Chemistry specialization. Currently, he is pursuing Ph.D in Organic Chemistry at University of Kalyani.",
    image: subrata_profile,
    location: "",
  },
  {
    name: "Srimanta Das",
    phone: "9609827154",
    mail: "dassrimanta10@gmail.com",
    address: "Rangamati, Berhampore, West Bengal, PIN-742405",
    about: "Works at Operating Department in Indian Railways.",
    about1:
      "He completed master of science in Mathematics from University of Kalyani.",
    image: srimanta_profile,
    location: "",
  },
  {
    name: "Dipankar Adak",
    phone: "8927113424",
    mail: "dipankaradak94@gmail.com",
    address: "A9/87, Kalyani, Nadia, West Bengal, PIN-741235, ",
    about: "SACT in the Department of Physics, Kalyani Mahavidyalaya.",
    about1:
      "He completed master of science in Physics with Nuclear & Particle Physics specialization. Additionally, he is pursuing Ph.D at Calcutta University.",
    image: dipankar_profile,
    location: "#",
  },
  {
    name: "Avijit Maity",
    phone: "9382696433",
    mail: "maityavijit010@gmail.com",
    address: "Patharpratima, South 24 Parganas, West Bengal, PIN-743371",
    about:
      "He is a proficient professional holding B.Tech in Information Technology from  University of Kalyani and pursuing M.Tech in Computer Science and Engineering from University of Calcutta. His academic background reflects a strong foundation in both theoretical and practical aspects of IT and computer science. With a keen interest in web development, he has honed his skills in creating dynamic and user-friendly websites. His expertise includes front-end technologies as well as back-end frameworks and databases, enabling him to build  efficient web application.",
    about1: "",
    image: avijit_profile,
    location: "",
  },
  {
    name: "Sumit Mondal",
    phone: "7047825468",
    mail: "smondalonly@gmail.com",
    address: "Patharpratima, South 24 Parganas, West Bengal, PIN-743347",
    about: "Freelancer.",
    about1:
      "He completed B.Tech in IT from University of Kalyani. Aspired to be a full stack developer. Seeking a demanding position in a respectable company where he can apply his technical, database, and managerial skills to support the expansion of the organization and broaden his knowledge of recent and emerging developments in the IT sector.",
    image: sumit_profile,
    location: "",
  },
  {
    name: "Pushpendu Jana",
    phone: "8336870054",
    mail: "pushpendu007@gmail.com",
    address: "Patharpratima, South 24 Parganas, West Bengal, PIN-743371",
    about: "Working professional at Versalence Infocomm Pvt Ltd.",
    about1:
      "He completed B.Tech in IT from University of Kalyani. Aspired to be a full stack developer. Seeking a demanding position in a respectable company where he can apply his technical, database, and managerial skills to support the expansion of the organization and broaden his knowledge of recent and emerging developments in the IT sector.",
    image: pushpendu_profile,
    location:
      "https://www.google.com/maps/place/Puspendu+jana/@21.797925,88.344513,476m/data=!3m1!1e3!4m6!3m5!1s0x3a03b338a6d28bd9:0x4a8542b814eb78d9!8m2!3d21.7987076!4d88.34389!16s%2Fg%2F11p_5s82yw?authuser=0&entry=ttu",
  },
  {
    name: "Uttam Biswas",
    phone: "9330073637",
    mail: "ubiswas602@gmail.com",
    address: "Bongaon, North 24 Parganas, West Bengal, PIN-743710",
    about: " Currently works as data entry operator in Airtel.",
    about1:
      "He is pursuing master of arts in English from University of Kalyani (DODL).",
    image: uttam_profile,
    location: "",
  },
  {
    name: "Titam Sana",
    phone: "9433249750",
    mail: "titamsana@gmail.com",
    address: "Uluberia, Howrah, West Bengal, PIN-711016",
    about:
      "He is pursuing B.Tech in Electronics and Instrumentation Engineering at University of Kalyani.",
    about1: "",
    image: titam_profile,
    location: "",
  },
  {
    name: "Parvez Mosarof",
    phone: "6290048803",
    mail: "pmosharofku@gmail.com",
    address: "Kushmandi, Dakshin Dinajpur, 733121",
    about: "He completed M.Sc in Zoology from the University of Kalyani.",
    about1: "",
    image: parvez_profile,
    location: "",
  },
  {
    name: "Subhadip Maiti",
    phone: "9883908534",
    mail: "Subhadipmaiti526@gmail.com",
    address: "Kshetramohanpur, Pathar Pratima, South 24 Parganas, Pin-743371",
    about:
      "He is pursuing M.Sc in Data Science at the University of Kalyani. He completed B.Sc in Mathematics from Ramakrishna Mission Vivekananda Centenary College.",
    about1: "",
    image: subhadip_profile,
    location: "",
  },
  {
    name: "Mursalin Nadim Zahin",
    phone: "8346987313",
    mail: "zidankhan95933@gmail.com",
    address: "Deshbandhupara, Raiganj, Uttar Dinajpur, Pin-733134",
    about:
      "He is pursuing M.Sc in Mathematics at Seacom Skills University. He completed B.Sc in Mathematics from the University of Gour Banga.",
    about1: "",
    image: zahin_profile,
    location: "",
  },
  {
    name: "Sayan Bairagya",
    phone: "7076172800",
    mail: "bluesayan33@gmail.com",
    address: "Mukundapur, Nabadwip, Ushidpur, Nadia, Pin-741301",
    about:
      "He is pursuing M.Sc in Data Science at the University of Kalyani. He completed B.Sc in Mathematics from the University Of Kalyani.",
    about1: "",
    image: sayan_profile,
    location: "",
  },
  {
    name: "Soubhik Barma",
    phone: "9851250554",
    mail: "barmasoubhik@gmail.com",
    address: "Cooch Behar, West Bengal, 736131",
    about: "Pursuing bachelor of education from BSAEU,did post graduation in Msc in zoology from Kalyani University,did graduation in zoology from Bidhannagar college.",
    about1: "",
    image: soubhik_profile,
    location: "",
  },
  {
    name: "Sumit Halder",
    phone: "9093888697",
    mail: "--",
    address: "--",
    about: "He completed M.Sc in Zoology from the University of Kalyani.",
    about1: "",
    image: sumit2_profile,
    location: "",
  },
];

